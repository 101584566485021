import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

import IconFacebook from 'assets/font-awesome/facebook.svg';
import IconInstagram from 'assets/font-awesome/instagram.svg';
import Logo from 'assets/img/logo.svg';


function IndexNavbar() {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  return (
    <Navbar className={classnames("fixed-top")} expand="lg">
      <Container>
        <div className="navbar-translate">
          <Link to="/" title="X Body by Suzana" onClick={() => toggleNavbarCollapse(false)}>
            <img 
              src={Logo} 
              alt="Logo" width="120" height="58" 
              className="logo__img"
              />
          </Link>
          <button
            title="Menu"
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/xbodybysuzana"
                target="_blank"
                title="Facebook">
                <img 
                  className="social-icon social-icon--facebook"
                  src={IconFacebook}
                  alt="Facebook"
                  width="17"
                  height="19"
                  />
                <p className="d-lg-none">&nbsp;&nbsp;Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/xbody_by_suzana/"
                target="_blank"
                title="Instagram"
              >
                <img 
                  className="social-icon social-icon--instagram"
                  src={IconInstagram}
                  alt="Instagram"
                  width="17"
                  height="19"
                />
                <p className="d-lg-none">&nbsp;&nbsp;Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="nas-tim" onClick={() => toggleNavbarCollapse(false)}>Naš tim</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="cjenik" onClick={() => toggleNavbarCollapse(false)}>Cjenik</Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
