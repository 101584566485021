import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
import 'assets/css/custom.css';

// others
import IndexNavbar from "components/Navbars/IndexNavbar.js";
const Footer = lazy(() => import ("components/Footers/Footer.js"));

// pages
const HomePage = lazy(() => import('views/HomePage'));
const ProfilePage = lazy(() => import("views/examples/ProfilePage.js"));
const CjenikPage = lazy(() => import("views/examples/CjenikPage.js"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <IndexNavbar />
    <Suspense>
      <Switch>
        <Route
          exact
          path="/" 
          render={(props) => <HomePage {...props} />} />
        <Route
          exact
          path="/nas-tim"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          exact
          path="/cjenik"
          render={(props) => <CjenikPage {...props} />}
        />
      </Switch>
    </Suspense>
    <Footer />
  </BrowserRouter>
);
